import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { EquipoComponent } from './components/equipo/equipo.component';
import { ScrollToTopComponent } from './shared/scroll-to-top/scroll-to-top.component';
import { QuienessomosComponent } from './shared/quienessomos/quienessomos.component';
import { ObjetivosComponent } from './shared/objetivos/objetivos.component';
import { HistoriaComponent } from './shared/historia/historia.component';
import { FormComponent } from './shared/form/form.component';
import { DlogComponent } from './shared/dlog/dlog.component';
import { DeconComponent } from './shared/decon/decon.component';
import { GaleriaComponent } from './shared/galeria/galeria.component';
import { ProyectosComponent } from './shared/proyectos/proyectos.component';
import { VoluntariadoComponent } from './shared/voluntariado/voluntariado.component';


const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'signup',           component: SignupComponent },
    { path: 'landing',          component: LandingComponent },
    { path: 'nucleoicons',      component: NucleoiconsComponent },
    { path: 'equipo',           component: EquipoComponent},
    { path: 'scrolltotop',      component: ScrollToTopComponent},
    { path: 'quienessomos',     component: QuienessomosComponent},
    { path: 'objetivos',        component: ObjetivosComponent},
    { path: 'historia',         component: HistoriaComponent},
    { path: 'form',             component: FormComponent},
    { path: 'dlog',             component: DlogComponent},
    { path: 'decon',            component: DeconComponent},
    { path: 'galeria',          component: GaleriaComponent},
    { path: 'proyectos',        component: ProyectosComponent},
    { path: 'voluntariado',     component: VoluntariadoComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
