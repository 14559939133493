import {Component, Input} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-content',
    template: `
    <div class="modal-header">
        <h5 class="modal-title text-center" style="font-weight: 600;">Como realizar tus Donaciones:</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
    <b style="font-weight: 600;">Fundación C.R.E.E.R.</b> Acepta todas las donaciones que ustedes, nuestros amables benefactores, tengan
    a bien realizarlas, pero para una mejor organización por parte de nuestros colaboradores,
    las hemos clasificado en dos categorias:<br><br>
    <b style="font-weight: 600;">Donaciones lógisticas:</b> Comprende todas aquellas donaciones que sean de caracter físico, ya sean alimentos, muebles, artefactos o ropa en buen estado.
    <br><br>
    <b style="font-weight: 600;">Donaciones Económicas:</b> Son todas aquellas contribuciónes económicas que ingresan a la fundación ya sean en efetivo
 o por cualquier medio de pago digital.(Transferencias Interbancarias, link de pagos, Tarjetas de Crédito)
    </div>
    <div class="modal-footer">
        <div class="left-side">
            <button type="button" [routerLink]="['/dlog']" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Logísticas</button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
            <button type="button" [routerLink]="['/decon']" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">Económicas</button>
        </div>
    </div>
    `
})
export class NgbdModalContent {
    @Input() name;

    constructor(public activeModal: NgbActiveModal) {}
}

@Component({
    selector: 'app-modal-component',
    templateUrl: './modal.component.html'
})
export class NgbdModalComponent {
    constructor(private modalService: NgbModal) {}
    open() {
        const modalRef = this.modalService.open(NgbdModalContent);
        modalRef.componentInstance.name = 'World';
    }
}
