import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { ScrollToTopComponent } from './shared/scroll-to-top/scroll-to-top.component';
import { DlogComponent } from './shared/dlog/dlog.component';
import { DeconComponent } from './shared/decon/decon.component';
import { GaleriaComponent } from './shared/galeria/galeria.component';
import { ProyectosComponent } from './shared/proyectos/proyectos.component';
import { VoluntariadoComponent } from './shared/voluntariado/voluntariado.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ScrollToTopComponent,
    DlogComponent,
    DeconComponent,
    GaleriaComponent,
    ProyectosComponent,
    VoluntariadoComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
